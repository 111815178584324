.wallpaper {
    :global {
        .ant-upload {
            height: 150px;
            margin-bottom: 20px;
        }

        .ant-card-cover {
            height: 140px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .ant-card {
            .ant-card-body {
                display: none;
            }
        }
    }
}

.effect {
    background-size: 400%;
    background-position: 0% 100%;
    -webkit-animation: gradient 7.5s ease-in-out infinite;
    animation: gradient 7.5s ease-in-out infinite;
    transform: translate3d(0,0,0);
}

@-webkit-keyframes gradient {
    50% {
        background-position: 100% 0;
    }
}

@keyframes gradient {
    50% {
        background-position: 100% 0;
    }
}

.banner {
    height: 480px;
    position: relative;
    overflow: hidden;
    background-color: #e5e5e5;

    :global {
        .nav {
            z-index: 2;
            position: absolute;
            bottom: 0;
        }

        .background {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            // filter: blur(4px);
            // transform: scale(1.1);
        }

        .search {
            position: absolute;
            top: 50%;
            z-index: 2;
            left: 50%;
            width: 800px;
            transform: translate(-50%, -50%);
            .ant-tabs-nav .ant-tabs-tab-active,
            .ant-tabs {
                color: #fff;
            }

            .ant-input-affix-wrapper {
                border: 1px solid #ffffff;

                .ant-input {
                    height: auto;
                }
            }

            .ant-input {
                border: 1px solid #ffffff;
                height: 40px;
            }

            input {
                text-indent: 5px;
                font-size: 14px;
            }

            .i-icon {
                cursor: pointer;
            }

            h2 {
                text-align: center;
                font-weight: normal;
                font-family: webfontx;
                color: rgb(255, 255, 255);
                font-size: 34px;
                padding-bottom: 20px;
            }

            // .ant-tabs-nav-scroll {
            //     text-align: center;
            // }

            .ant-tabs-nav {
                margin: 0 0;
                margin-bottom: 0px;
                &::before {
                    border-bottom: none;
                }
            }
        }
    }

    @media (min-width: 1300px) and (max-width: 1500px) {}

    /*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
    @media (min-width: 768px) and (max-width: 1299px) {
        height: 360px;
        :global {
            .search {
                width: 600px;

                h2 {
                    font-size: 36px;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                }
            }
        }
    }

    /*在480 和767 像素之间的屏幕里，超小屏幕，主要是手机*/
    @media (min-width: 480px) and (max-width: 767px) {
        height: 340px;
        :global {
            .search {
                width: 85%;
                h2 {
                    font-size: 24px;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                }
                .ant-input,.ant-input-search-button,.ant-input-affix-wrapper{
                    height: 34px;
                    border: none;
                }
            }
        }
    }

    /*在小于480 像素的屏幕，微小屏幕，更低分辨率的手机*/
    @media (max-width: 479px) {
        height: 340px;
        :global {
            .search {
                width: 85%;
                h2 {
                    font-size: 24px;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                }
                .ant-input,.ant-input-search-button,.ant-input-affix-wrapper{
                    height: 34px;
                    border: none;
                }
            }
           
        }
    }

}